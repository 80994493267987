export interface IState {
	mainMenuItems: IMainMenuItem[]
}
export interface IMainMenuItem {
	name: string,
	uri: string,
	isCurrent: boolean,
	id: string,
	isExpanded: boolean,
	hasChildren: boolean,
	children: IItem[],
	level: number,
	isParentToCurrentPage: boolean
}
export interface IItem {
	name: string,
	uri: string,
	isCurrent: boolean
}
const deafultState: IState = {
	mainMenuItems: []
}

export default deafultState;
